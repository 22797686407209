


























import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator';
import { Money } from 'v-money';
import { DiscountType } from "@/constants/Order";

@Component({
    components:{
        Money
    }
})
export default class Discount extends Vue{
    @Ref('valueRef') valueRef !: any;
    @Prop() value!: number;
    @Prop() isOnlyPercent!: boolean;

    discountType = DiscountType;
    productDiscountType: DiscountType = DiscountType.Percent;
    discount: number = 0;

    moneyOption: any = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };

    created(){
    }

    onChangeDiscountType(type: DiscountType){
        this.productDiscountType = type;

        if (this.productDiscountType === DiscountType.Percent) {
            if ( this.value && this.value > 100) {
                this.value = 0;
            }
        }
    }

    @Watch('value', {deep: true, immediate: true})
    onValueChanged(newValue: number){
        if (this.isOnlyPercent){
            if (newValue >= 100){
                this.discount = 100;
            }
            else if (newValue <= -100){
                this.discount = -100;
            }
        }
        else{
            this.discount = newValue ? newValue : 0;
        }
    }

    @Watch('discount', {deep: true, immediate: true})
    onDiscountChanged(newValue: number, oldValue: number){
        if ((newValue > 100 || newValue < -100) && !this.isOnlyPercent) {
            this.productDiscountType = DiscountType.Money;
        }
        else if (newValue <= 100 || newValue >= -100) {
            this.productDiscountType = DiscountType.Percent;
        }

        this.$emit('changed', newValue, this.productDiscountType);
    }

    focus(){
        let inputEl = this.valueRef.$el;

        if (inputEl) {
            inputEl.focus();
            inputEl.select();
        }
    }
}
