


























import {Component, Vue, Watch, Ref} from 'vue-property-decorator';

@Component({

})
export default class CellRendererName extends Vue {
    @Ref('noteInput') noteInput!: any;
    params: any = null;
    name: string = "";
    code: string = "";
    unitName: string = "";
    isShowNote: boolean = false;

    created(){
        this.name = this.params.value;
        this.code = this.params.data.product.code;
        this.unitName = this.params.data.unitName;
    }

    get note() {
        return this.params.data.note;
    }

    set note(value: string){
        this.params.data.note = value;
    }

    get productName(){
        let items: Array<string> = new Array<string>();

        if (this.code) {
            items.push(this.code);
        }

        if (this.name) {
            items.push(this.name);
        }

         if (this.unitName) {
            items.push(this.unitName);
        }

        return items.join(' - ');
    }

    showNote(){
        this.isShowNote = !this.isShowNote;

        if (this.isShowNote) {
            // this.params.node.setRowHeight(72);

            setTimeout(()=>{
                this.noteInput.$el.querySelector('input').focus();
            },50)
        } else {
            // this.params.node.setRowHeight(48)
        }
    }

    // onNoteBlur(){
    //     this.isShowNote = !this.isShowNote;
    // }

    @Watch('note', {deep: true, immediate: true})
    onNoteChanged(value: string, oldValue: string){
        if (value !== oldValue) {
            this.params.data['note'] = value;
        }
    }

    get allowAddNote(){
        return !this.params.isOrderChildOfOrderContract && !this.params.isReturn
    }
}
