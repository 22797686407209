



















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {AttributeType} from '@/constants/Attribute';
import {CustomAttributeResult} from '@/models/attribute/CustomAttributeResult';

@Component
export default class CustomAttribute extends Vue {
    @Prop() attribute!: CustomAttributeResult;
    @Prop({default: ''}) id!: string;
    @Prop({default: ''}) value!: string;
    @Prop({default: false}) disabled!: boolean;

    attributeType = AttributeType;
    _selectedValue!: string | string[] | number;

    get selectedValue() {
        return this._selectedValue;
    }

    set selectedValue(value) {
        this._selectedValue = value;
    }
}
