import {Order} from "@/models/order/Order";
import { genarateId } from "@/common";
import { FormType } from "@/constants/Order";

export class OrderTab {
    id: string;
    name: string;
    isActive: boolean;
    data: Order;
    formType: FormType;

    constructor(name: string){
        this.id = genarateId();
        this.name = name;
        this.data = new Order();
        this.formType = FormType.Normal;
    }
}