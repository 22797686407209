import {OrderDetail} from "@/models/order/OrderDetail";
import {Receiver} from "@/models/order/Receiver";
import {Payment} from "@/models/order/Payment";
import {OrderCustomer} from "@/models/order/OrderCustomer";
import {DiscountType} from "@/constants/Order";

export class OrderExchangeReturn {
    id: string;
    note: string;
    fromOrderId: string;
    orderCustomer: OrderCustomer;
    receiver: Receiver;
    storeId: string;
    storeCode: string;
    storeName: string;
    orderPayments: Payment[];
    paidPrice: number;
    return: OrderReturn;
    exchange: OrderExchange;

    constructor() {
        this.id = '';
        this.note = '';
        this.fromOrderId = '';
        this.orderCustomer = new OrderCustomer();
        this.receiver = new Receiver("", "");
        this.storeId = '';
        this.storeCode = '';
        this.storeName = '';
        this.orderPayments = new Array<Payment>();
        this.paidPrice = 0;
        this.return =  new OrderReturn();
        this.exchange = new OrderExchange();
    }

}

export class OrderReturn {
    discount: number;
    discountType: DiscountType;
    vAT: number;
    orderDetails: Array<OrderDetail>;
    totalPrice: number;
    returnCharge: number;
    isImportInventory: boolean;
    totalAmount: number;

    constructor() {
        this.discount = 0;
        this.discountType = DiscountType.Percent;
        this.vAT = 0;
        this.orderDetails = new Array<OrderDetail>();
        this.totalPrice = 0;
        this.returnCharge = 0;
        this.isImportInventory = true;
        this.totalAmount = 0;
    }
}

export class OrderExchange {
    discount: number;
    discountType: DiscountType;
    vAT: number;
    orderDetails: OrderDetail[];
    totalPrice: number;
    isExportInventory: boolean;

    constructor() {
        this.discount = 0;
        this.discountType = DiscountType.Percent;
        this.vAT = 0;
        this.orderDetails = new Array<OrderDetail>();
        this.totalPrice = 0;
        this.isExportInventory = true;
    }
}
