





















































































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.min.css';
import { Vietnamese as VietnameseLocale } from 'flatpickr/dist/l10n/vn.js';
import { UserConfigService } from '@/store/user-management/UserConfigService';
import { OrderService } from '@/views/order/OrderService';
import { ProductService } from "@/views/order/ProductService";
import { Receiver } from "@/models/order/Receiver";
import _each from 'lodash/each';
import _head from 'lodash/head';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';
import _cloneDeep from 'lodash/cloneDeep';
import { Order } from '@/models/order/Order';
import { OrderDetail } from '@/models/order/OrderDetail';
import { ProductSearchResult } from '@/models/product/ProductSearchResult';
import { Pane, Splitpanes } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import {
    DiscountType,
    FormType,
    InstallationMethod,
    InstallationMethods,
    OrderType,
    PaymentMethods,
    ShippingMethod,
    ShippingMethods,
    Status
} from "@/constants/Order";
import { ActionResult } from '@/models/ActionResult';
import { calculateOrderDetailVatMoney, formatNumber } from "@/common";
import { Money } from 'v-money';
import { ConfigService, ConfigSystem, VATType } from '@/services/config/ConfigService';
import { Getter } from "vuex-class";
import CustomAttribute from '@/components/custom-attribute/CustomAttribute.vue';
import CustomerAddressService from '@/views/customer/address/CustomerAddressService';
import { CustomAttributeResult } from '@/models/attribute/CustomAttributeResult';
import { AttributeResult } from '@/models/attribute/AttributeResult';
import { CustomerAddress } from '@/models/customer/CustomerAddress';
import moment from 'moment';
import { Payment } from '@/models/order/Payment';
import { OrderTab } from "@/models/order/OrderTab";
import VueSimpleSuggest from 'vue-simple-suggest';
import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
import { ReceiverService } from "@/views/order/ReceiverService";
import { ReceiverGetByCustomerResult } from '@/models/order/ReceiverGetByCustomerResult';
import { CustomerService } from "@/services/customer/CustomerService";
import { SourceResult } from "@/models/order/SourceResult";
import { SourceDetailResult } from "@/models/order/SourceDetailResult";
import OrderContractList from "@/views/order/order-contract/OrderContractList.vue";
import { SettingColumnService } from '@/services/SettingColumnService';
import { CustomerDetailResult } from '@/models/customer/CustomerDetailResult';
import { OrderGetByIdResult } from '@/models/order/OrderGetByIdResult';
import { OrderCustomer } from "@/models/order/OrderCustomer";
import GlobalEvents from 'vue-global-events'
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";
import OrderReturnList from '@/views/order/order-return/OrderReturnList.vue';
import Discount from "@/components/order/Discount.vue";
import ProductAgGrid from '@/views/order/order-edit/product/ProductAgGrid.vue';
import { OrderExchange, OrderExchangeReturn, OrderReturn } from "@/models/order/OrderExchangeReturn";
import { TabState } from "@/store/order/order-state";
import { namespace } from 'vuex-class';
import OrderUploadFiles from '@/views/order/order-edit/OrderUploadFiles.vue';
import { PaymentMethod } from "@/constants/Order";

// Component.registerHooks([
//     'beforeRouteEnter',
//     // 'beforeRouteUpdate'
// ]);

const orderStoreModule = namespace('order');
@Component({
    components: {
        vSelect,
        Splitpanes,
        Pane,
        flatPickr,
        Money,
        CustomAttribute,
        VueSimpleSuggest,
        OrderContractList,
        GlobalEvents,
        CustomerSuggestion: () => import(/* webpackChunkName: "CustomerSuggestion" */ "@/components/suggestion/customer-suggestion/CustomerSuggestion.vue"),
        CustomerAddressList: () => import(/* webpackChunkName: "CustomerAddressList" */ "@/views/customer/address/CustomerAddressList.vue"),
        UserSuggestion: () => import(/* webpackChunkName: "UserSuggestion" */ "@/components/suggestion/user-suggestion/UserSuggestion.vue"),
        CustomerAddressForm: () => import(/* webpackChunkName: "CustomerAddressForm" */ '@/views/customer/address/CustomerAddressForm.vue'),
        OrderPayment: () => import(/* webpackChunkName: "OrderPayment" */ '@/views/order/order-edit/OrderPayment.vue'),
        OrderCustomerAddress: () => import(/* webpackChunkName: "OrderCustomerAddress" */ '@/views/order/order-edit/OrderCustomerAddress.vue'),
        OrderPriceTable: () => import(/* webpackChunkName: "OrderPriceTable" */ '@/views/order/order-edit/OrderPriceTable.vue'),
        SelectProduct: () => import(/* webpackChunkName: "SelectProduct" */ "@/views/order/order-edit/SelectProduct.vue"),
        ProductSearch: () => import(/* webpackChunkName: "ProductSearch" */ "@/views/order/order-edit/product/ProductSearch.vue"),
        OrderReturnList,
        Discount,
        ProductAgGrid,
        OrderUploadFiles
    }
})

export default class OrderEdit extends Vue {
    @Getter("oidcUser") oidcUser!: any;
    @Ref('orderPaymentRef') orderPaymentRef: any;
    @Ref('customerSuggestion') customerSuggestion!: any;
    @Ref('orderCustomerAddress') orderCustomerAddress!: any;
    @Ref('orderPriceTable') orderPriceTable!: any;
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Ref('productSuggest') productSuggest!: any;
    @Ref('orderDiscount') orderDiscount!: any;
    @Ref('receiverSuggest') receiverSuggest!: any;
    @Ref('orderContractListComponent') orderContractListComponent!: any;
    @Ref('orderReturnList') orderReturnList !: any;
    @Ref('productAgGrid') productAgGrid !: any;
    @Ref('productAgGridReturn') productAgGridReturn !: any;
    @Ref('productSuggestExchange') productSuggestExchange!: any;
    @Ref('fpRangeComponent') fpRangeComponent!: any;

    @orderStoreModule.Getter('getTab') getTab!: any;

    private userConfigService: UserConfigService;
    private orderService: OrderService;
    private productService: ProductService;
    private configService: ConfigService;
    private customerAddressService: CustomerAddressService;
    private receiverService: ReceiverService;
    private customerService: CustomerService;
    private settingColumnService: SettingColumnService;

    constructor() {
        super();
        this.userConfigService = new UserConfigService();
        this.orderService = new OrderService();
        this.productService = new ProductService();
        this.configService = new ConfigService();
        this.customerAddressService = new CustomerAddressService();
        this.receiverService = new ReceiverService();
        this.customerService = new CustomerService();
        this.settingColumnService = new SettingColumnService();
    }

    readonly ORDER_TAB = 'Đơn';
    readonly RETURN_TAB = 'Trả hàng';
    readonly formatType = 'DD-MM-YYYY HH:mm';

    orderStatus = Status;
    discountType = DiscountType;
    vatType = VATType;
    formTypeEnum = FormType;
    orderDetailsSelected: Array<OrderDetail> = new Array<OrderDetail>();

    configPaymentTermDatePicker: any = {
        locale: VietnameseLocale,
        enableTime: false,
        dateFormat: 'd-m-Y',
        defaultDate: null,
        disable: [
            {
                from: moment().subtract(10, 'years').format("DD-MM-YYYY"),
                to: moment().subtract(1, 'days').format("DD-MM-YYYY")
            }
        ]
    };

    configDateTimePicker: any = {
        locale: VietnameseLocale,
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        defaultDate: new Date()
    };

    configDateTimePickerRange = {
        locale: VietnameseLocale,
        enableTime: true,
        dateFormat: 'd-m-Y H:i',
        mode: 'range',
        defaultDate: [new Date(), new Date()]
    };

    moneyOption: any = {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };

    installationMethods: any = InstallationMethods;
    shippingMethods: any = ShippingMethods;
    // paymentMethods: any = PaymentMethods;
    // productSelectedColumnDefs: any = null;
    inventories: any = [];
    branches: any = [];
    order: Order = new Order();
    deliveryDate: any = null;
    orderDate: any = null;
    customerAddresses: any = [];
    customerAddressSelected: CustomerAddress = new CustomerAddress();
    vatUnit: string = "";
    // configSystem: ConfigSystem = new ConfigSystem(null, false, 10, VATType.VATInSummary, false, false);
    tabs: Array<OrderTab> = new Array<OrderTab>(new OrderTab(`${this.ORDER_TAB} 1`));
    tabIndex: number = 0;
    forceRender: number = 0;
    lastTabIndex: number = 1;
    lastReturnTabIndex: number = 0;
    formType: FormType = FormType.Normal;
    isActiveInventoryPopup: boolean = false;
    forceDeliveryDateRender: number = 0;
    paymentTermDate: any = null;
    managerOfCustomer: Array<any> = [];

    get currentUser() {
        return this.oidcUser;
    }

    async created() {
        let result = await this.userConfigService.getUserConfig(this.currentUser.userId);

        if (result.code > 0) {
            let resultData = result.data;

            if (resultData) {
                this.inventories = resultData.inventories;
                this.branches = resultData.branches;
            }
        }

        if (this.tabs.length === 0) {
            this.addTab();
        }

        let tab: OrderTab = this.tabs[this.tabIndex];
        this.order = tab.data;

        if (this.inventories && this.inventories.length > 0) {
            let inventory: any = _head(this.inventories);

            if (inventory) {
                this.order.storeId = inventory.id;
                this.order.storeCode = inventory.erpCode;
                this.order.storeName = inventory.name;
            }
        }

        if (this.branches && this.branches.length > 0) {
            let branch: any = _head(this.branches);

            if (branch) {
                this.order.branchId = branch.id;
                this.order.branchName = branch.name;
            }
        }
    }

    async beforeMount() {
        if (this.$route.query) {
            let customerId = this.$route.query.customerId as string;

            if (customerId) {
                let resultCustomerDetail = await this.customerService.getDetail(customerId);

                if (resultCustomerDetail.code > 0) {
                    let customerData = resultCustomerDetail.data as CustomerDetailResult;

                    await this.getOrderInfoOfCustomer(customerData);
                }
            }
        }

        const tabState: TabState = this.$store.getters['order/getTab'];
        let orderId = '';

        if (tabState && tabState.type === FormType.Return) {
            this.formType = tabState.type as FormType;

            if (!tabState.id) {
                this.addTab();

                return false;
            }

            orderId = tabState.id;
        }

        if (this.$route.params && this.$route.params.id) {
            orderId = this.$route.params.id;
        }

        if (orderId) {
            this.getOrderById(orderId);
        }

    }

    beforeDestroy() {
        const tab: TabState = {
            id: '',
            type: null,
        };

        this.$store.dispatch('order/addTab', tab);
    }

    @Watch('getTab')
    onGetTab(data: TabState) {
        if (data && !!data.id) {
            this.formType = data.type as FormType;

            this.getOrderById(data.id);
        }
    }

    get listSelectedInstallation() {
        return this.order.installations.map((user: any) => {
            return new SuggestionModel(user.id, user.fullName, '', user.avatar, true, user);
        })
    }

    get listSelectedShipping() {
        return this.order.shippings.map((user: any) => {
            return new SuggestionModel(user.id, user.fullName, '', user.avatar, true, user);
        })
    }

    onDeleteProduct(id: string) {
        this.order.orderDetails = _filter(this.order.orderDetails, (value: OrderDetail) => {
            return value.id !== id;
        });
    }

    onOrderDetailListChanged(data: any) {
        this.order.amount = data.sumTotalPrice;
        this.order.weight = this.order.weight ? this.order.weight : data.sumWeight;

        // if (this.isFirstEdit && this.order.vat !== data.sumVat){
        //     this.$nextTick(() => {
        //         this.isFirstEdit = false;
        //     })
        // }
        // else{
        //     this.order.vat = data.sumVat;
        // }

        this.order.vat = data.sumVat;
        this.order.orderDetails = data.orderDetails;
    }

    onOrderDetailSelected(data: any) {
        this.orderDetailsSelected = data;
    }

    onDeleteOrderDetailReturn(id: string) {
        this.order.orderDetailsReturn = _filter(this.order.orderDetailsReturn, (value: OrderDetail) => {
            return value.id !== id;
        });
    }

    onOrderDetailsReturnChanged(data: any) {
        this.order.amountReturn = data.sumTotalPrice;
        this.order.vatReturn = data.sumVat;
    }

    @Watch('order.shippingMethod')
    onShippingMethodChanged(value: ShippingMethod, oldValue: ShippingMethod) {
        if ((oldValue === undefined || oldValue === null) && !!this.order.id) {
            return;
        }

        if (value === ShippingMethod.Internal && this.order.shippings.length < 1) {
            if (this.order.installationMethod === InstallationMethod.Internal && this.order.installations.length > 0) {
                this.order.shippings = _cloneDeep(this.order.installations);

                _each(this.order.shippings, (item: any) => {
                    item.text = item.fullName;
                });
            }
        }
    }

    @Watch('order.installationMethod')
    onInstallationMethodChanged(value: InstallationMethod, oldValue: InstallationMethod) {
        if ((oldValue === undefined || oldValue === null) && !!this.order.id) {
            return;
        }

        if (value === InstallationMethod.Internal && this.order.installations.length < 1) {
            if (this.order.shippingMethod === ShippingMethod.Internal && this.order.shippings.length > 0) {
                this.order.installations = _cloneDeep(this.order.shippings);

                _each(this.order.installations, (item: any) => {
                    item.text = item.fullName;
                });
            }
        }
    }

    get totalMoney() {
        let amount = this.order.amount;
        let discount = this.order.discount ? this.order.discount : 0;
        let shippingPrice = this.order.shippingPrice ? this.order.shippingPrice : 0;
        let vat = this.order.vat ? this.order.vat : 0;
        let moneyIncludeDiscount = 0;

        // if (this.order.discountType === DiscountType.Percent) {
        //     moneyIncludeDiscount = amount * (1 - discount / 100);
        // } else if (this.order.discountType === DiscountType.Money) {
        //     moneyIncludeDiscount = amount - discount;
        // }
        //
        // let money = moneyIncludeDiscount + vat + shippingPrice;
        // this.order.totalPrice = money;
        //
        // let excessMoney = (this.order.paidPrice ? this.order.paidPrice : 0) - money;
        // this.order.excessMoney = excessMoney;

        let money = amount + vat;

        if (this.order.discountType === DiscountType.Percent) {
            moneyIncludeDiscount = money * (1 - discount / 100);
        } else if (this.order.discountType === DiscountType.Money) {
            moneyIncludeDiscount = money - discount;
        }

        money = moneyIncludeDiscount + shippingPrice;
        this.order.totalPrice = money;

        let excessMoney = (this.order.paidPrice ? this.order.paidPrice : 0) - money;
        this.order.excessMoney = excessMoney;

        return money;
    }

    private validateRequest(status: Status | null = null) {
        // if (this.order.orderDetails.length === 0) {
        //     this.$vs.notify({
        //         title: '',
        //         text: "Vui lòng chọn sản phẩm vào đơn hàng!",
        //         color: "danger"
        //     });
        //
        //     return false;
        // }

        if (status == Status.GoodsIssued || status == Status.Ordered) {
            let productsEqualZero = _filter(this.order.orderDetails, (item: OrderDetail) => {
                return item.quantity === 0;
            });

            if (productsEqualZero.length > 0) {
                let productName = _map(productsEqualZero, 'product.name');

                this.$vs.notify({
                    title: '',
                    text: `Số lượng sản phẩm ${productName.join(', ')} phải lớn hơn 0!`,
                    color: "danger"
                });

                return false;
            }
        }

        if (!this.order.storeId) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần chọn Kho xuất!",
                color: "danger"
            });

            return false;
        }

        if (!this.order.orderCustomer.customerId) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần chọn khách hàng!",
                color: "danger"
            });

            return false;
        }

        if (!this.order.receiver.fullName || !this.order.receiver.phoneNumber) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần nhập thông tin người nhận!",
                color: "danger"
            });

            return false;
        }

        if (!this.order.deliveryDate.from && !this.order.deliveryDate.to) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần chọn Ngày giao hàng!",
                color: "danger"
            });

            return false;
        }

        if (status !== Status.Draft) {
            if (!this.order.paymentTermDate) {
                this.$vs.notify({
                    title: '',
                    text: "Bạn cần chọn tuổi nợ!",
                    color: "danger"
                });

                return false;
            }
        }

        if (this.order.note.length > 4000) {
            this.$vs.notify({
                title: '',
                text: "Ghi chú không được vượt quá 4000 ký tự",
                color: "danger"
            });

            return false;
        }

        return true;
    }

    async save(status: Status = Status.Draft, previousStatus: Status = Status.Draft) {
        // TH thay đổi select.
        if (this.order.storeId) {
            let inventory = _find(this.inventories, (item: any) => {
                return item.id === this.order.storeId;
            });

            if (inventory) {
                this.order.storeCode = inventory.erpCode;
                this.order.storeName = inventory.name;
            }
        }

        // TH thay đổi select.
        if (this.order.branchId) {
            let branch = _find(this.branches, (item: any) => {
                return item.id === this.order.branchId;
            });

            if (branch) {
                this.order.branchName = branch.name;
            }
        }

        this.order.status = status;
        this.order.orderCustomer.customerAddress = this.customerAddressSelected ? this.customerAddressSelected : new CustomerAddress();

        _each(this.order.orderDetails, (item: OrderDetail) => {
            if (!item.vat)
                item.vat = 0;
        });

        this.openLoading(true);

        if (!this.order.id) {
            try {
                let result: ActionResult<string> = await this.orderService.insert(this.order);
                this.openLoading(false);

                if (result.code > 0) {
                    this.orderFormRefresh(true, this.tabIndex);
                }

                this.$vs.notify({
                    title: '',
                    text: result.message,
                    color: result.code <= 0 ? 'danger' : 'success'
                });
            } catch (error) {
                this.order.status = previousStatus;
                this.openLoading(false);

                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: 'danger'
                });
            }
        } else {
            try {
                this.order.orderDate = moment(this.order.orderDate).format();
                this.order.deliveryDate.from = moment(this.order.deliveryDate.from).format();
                this.order.deliveryDate.to = moment(this.order.deliveryDate.to).format();
                this.order.paymentTermDate = moment(this.order.paymentTermDate).format();

                _each(this.order.orderPayments, (item: Payment) => {
                    this.$delete(item, 'createTime');
                });

                let result: ActionResult<string> = await this.orderService.update(this.order);
                this.openLoading(false);

                if (result.code > 0) {
                    this.orderFormRefresh(false, this.tabIndex);
                    await this.$router.push({name: 'orderAdd'});
                }

                this.$vs.notify({
                    title: '',
                    text: result.message,
                    color: 'success'
                });
            } catch (error) {
                this.order.status = previousStatus;
                this.openLoading(false);

                this.$vs.notify({
                    title: '',
                    text: error.message,
                    color: error.code === 0 ? 'success' : 'danger'
                });

                if (error.code === 0) {
                    this.orderFormRefresh(false, this.tabIndex);
                    await this.$router.push({name: 'orderAdd'});
                }
            }
        }
    }

    onSave(status: Status) {
        let previousStatus = _cloneDeep(this.order.status);
        let isValid = this.validateRequest(status);

        if (!isValid) {
            this.order.status = previousStatus;

            return;
        }

        this.order.installations = _uniqBy(this.order.installations, function (e) {
            return e.id;
        });

        this.order.shippings = _uniqBy(this.order.shippings, function (e) {
            return e.id;
        });

        if (previousStatus !== status && status === Status.Ordered) {
            // Chọn lại chi nhánh hoặc kho
            if ((this.branches && this.branches.length > 1) || (this.inventories && this.inventories.length > 1)) {
                this.isActiveInventoryPopup = true;

                return;
            }
        }

        // this.order.status = status;
        this.save(status, previousStatus);
    }

    async onCustomerSelected(customers: any) {
        let customer = customers[0];

        if (customer && customer.data) {
            this.order.receiver = new Receiver("", "");

            await this.getOrderInfoOfCustomer(customer.data);
        }
    }

    onCustomerSelectedRemove(data: any) {
        this.order.orderCustomer = new OrderCustomer();
        this.order.receiver = new Receiver("", "");
    }

    formatNumber(value: any) {
        return formatNumber(value);
    }

    @Watch('order.shippingPrice')
    onOrderShippingPriceChanged(value: number, oldValue: number) {
        if (this.order.paidPrice) {
            let excessMoney = this.order.paidPrice - this.totalMoney;
            // this.order.excessMoney = excessMoney < 0 ? 0 : excessMoney;
            this.order.excessMoney = excessMoney;
        }
    }

    @Watch('order.paidPrice')
    onOrderPaidMoneyChanged(value: number, oldValue: number) {
        if (this.formType === FormType.Normal) {
            if (value < 1) {
                this.order.orderPayments = new Array<Payment>();
            }

            let excessMoney = value - this.totalMoney;
            // this.order.excessMoney = excessMoney < 0 ? 0 : excessMoney;
            this.order.excessMoney = excessMoney;
        } else {
            if (this.totalMoneyExchangeReturn < 0) {
                this.order.excessMoneyReturn = value - Math.abs(this.totalMoneyExchangeReturn);
            }
        }
    }

    @Watch('order.vat')
    onOrderVATChanged(value: number) {
        if (this.order.paidPrice) {
            this.order.excessMoney = this.order.paidPrice - this.totalMoney;
        }
    }

    openPopupCustomerAddress() {
        this.orderCustomerAddress.openPopup();
    }

    async onCustomerAddressRemoveSuccess(customerAddress: CustomerAddress) {
        if (this.customerAddressSelected.id === customerAddress.id) {
            this.order.orderCustomer.customerAddress.customAttributes = [];
            this.order.orderCustomer.address = '';
            this.order.routeName = "";
        }
    }

    async onCustomerAddressSaveSuccess(customerAddress: CustomerAddress) {
        let customAttributes = customerAddress.customAttributes;

        this.order.orderCustomer.customerAddress.customAttributes = customAttributes;
        this.order.orderCustomer.address = customerAddress.address;
        await this.getDefaultAttribute(customAttributes);

        let routeName = _map(customerAddress.routes, 'name');
        this.order.routeName = routeName.join(', ');

        this.customerAddressSelected = customerAddress;
    }

    openOrderPayment() {
        this.orderPaymentRef.openPopup();
    }

    onPaymentMethodSaveSuccess(value: number, items: Array<Payment>) {
        let sumPaidMoney = 0;

        if (items.length === 0)
            return;

        let payments: Array<Payment>

        payments = _filter(items, (item: Payment) => {
            return item.paymentMethod === PaymentMethod.Transfer || item.paymentMethod === PaymentMethod.Cash
        }) as Array<Payment>;

        _each(payments, (item: Payment) => {
            item.money = parseFloat((item.money ? item.money : 0).toString());

            sumPaidMoney = sumPaidMoney + parseFloat((item.money ? item.money : 0).toString());
        });

        this.order.orderPayments = items;
        this.order.paidPrice = sumPaidMoney;
    }

    @Watch('tabs')
    changeListFilter(value: number) {
        setTimeout(() => {
            this.forceRender += 1;
        }, 100)
    }

    onAddTab(id?: string) {
        // Trả hàng.
        if (typeof id === "string") {
            this.formType = FormType.Return;
            this.getOrderById(id);
        } else {
            this.formType = FormType.Normal;
            this.addTab();
        }

        if (this.$route.name !== 'orderAdd') {
            this.$router.push({name: 'orderAdd'});
        }
    }

    addTab() {
        let name = "";

        if (this.formType === FormType.Return) {
            this.lastReturnTabIndex = this.lastReturnTabIndex + 1;
            name = `${this.RETURN_TAB} ${this.lastReturnTabIndex}`;
        } else {
            this.lastTabIndex = this.lastTabIndex + 1;
            name = `${this.ORDER_TAB} ${this.lastTabIndex}`;
        }

        let tab = new OrderTab(name);
        tab.formType = this.formType;

        if (this.inventories && this.inventories.length > 0) {
            let inventory: any = _head(this.inventories);
            tab.data.storeId = inventory.id;
            tab.data.storeCode = inventory.erpCode;
            tab.data.storeName = inventory.name;
        }

        this.tabs.push(tab);
        this.order = tab.data;
        this.customerAddressTextHidden(false);

        if (this.formType === FormType.Normal) {
            this.deliveryDate = null;
            let from = this.order.deliveryDate.from ? moment(this.order.deliveryDate.from).format(this.formatType) : new Date();
            let to = this.order.deliveryDate.to ? moment(this.order.deliveryDate.to).format(this.formatType) : new Date();
            this.$set(this.configDateTimePickerRange, 'defaultDate', [from, to]);

            setTimeout(() => {
                this.forceDeliveryDateRender += 1;
            }, 100);
        }

        setTimeout(() => {
            this.tabIndex = this.tabs.length - 1;
        }, 50);
    }

    removeTab(item: any) {
        let orderTab = _find(this.tabs, (tab: OrderTab, index: number) => {
            return index === item.id;
        }) as OrderTab;

        if (orderTab.data.id) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Đóng ${orderTab.name}`,
                acceptText: this.$t('Accept'),
                cancelText: this.$t('Cancel'),
                text: `Thông tin của ${orderTab.name} sẽ không được lưu lại. Bạn có chắc chắn muốn đóng không?`,
                accept: () => {
                    this.$router.push({name: 'orderAdd'});
                    this.orderFormRefresh(false, item.id);
                }
            });

            return;
        }

        this.orderFormRefresh(false, item.id);
    }

    selectTab(tab: OrderTab, index: number) {
        this.tabIndex = index;
        this.order = tab.data;
        this.formType = tab.formType;
        this.orderDate = this.order.orderDate ? moment(this.order.orderDate).format(this.formatType) : new Date();
        this.paymentTermDate = this.order.paymentTermDate ? moment(this.order.paymentTermDate).format(this.formatType) : null;

        this.deliveryDate = null;
        let from = this.order.deliveryDate.from ? moment(this.order.deliveryDate.from).format(this.formatType) : new Date();
        let to = this.order.deliveryDate.to ? moment(this.order.deliveryDate.to).format(this.formatType) : new Date();
        this.$set(this.configDateTimePickerRange, 'defaultDate', [from, to]);

        setTimeout(() => {
            this.forceDeliveryDateRender += 1;
        }, 100);

        this.customerAddressTextHidden(false);
    }

    deliveryDateClosed(selectedDates: any, dateStr: any, instance: any) {
        if (selectedDates.length == 2) {
            let start = selectedDates[0];
            let end = selectedDates[1];
            this.order.deliveryDate.from = moment(start).format();
            this.order.deliveryDate.to = moment(end).format();
        } else {
            this.deliveryDate = null;
            selectedDates = null;

            setTimeout(() => {
                this.fpRangeComponent.fp.clear();
            }, 100);

            this.order.deliveryDate.from = null;
            this.order.deliveryDate.to = null;
        }
    }

    orderDateClosed(selectedDates: any, dateStr: any, instance: any) {
        let start = selectedDates[0];
        this.order.orderDate = moment(start).format();
    }

    paymentTermDateClosed(selectedDates: any) {
        let start = selectedDates[0];
        this.order.paymentTermDate = moment(start).format();
    }

    async onSearchReceivers(inputValue: string) {
        const result = await this.receiverService.search(this.order.orderCustomer.customerId, inputValue);

        return result.items;
    }

    onSuggestSelect(suggest: ReceiverGetByCustomerResult) {
        this.order.receiver.fullName = suggest.fullname;
        this.order.receiver.phoneNumber = suggest.phoneNumber;
    }

    openPopupOrderPriceTable() {
        this.orderPriceTable.openPopup();
    }

    onApplyPriceTable(data: any) {
        if (data) {
            let details = data.details;

            if (details.length > 0) {
                let productIds = _map(details, 'productId');
                let id = data.id;
                let name = data.name;
                let type = data.type;
                let code = data.code;

                _each(this.order.orderDetails, (orderDetail: OrderDetail) => {
                    let productId = orderDetail.product.id;

                    if (productIds.indexOf(productId) > -1) {
                        let item = new SourceResult();
                        item.id = id;
                        item.name = name;
                        item.details = details;

                        let priceTableExists = _find(orderDetail.sources, (priceTableItem: SourceResult) => {
                            return priceTableItem.id === item.id;
                        });

                        if (priceTableExists) {
                            priceTableExists.name = item.name;
                            priceTableExists.details = item.details;
                        } else {
                            if (orderDetail.sources) {
                                orderDetail.sources.splice(1, 0, item);
                            } else {
                                orderDetail.sources = [item];
                            }
                        }

                        orderDetail.sourceId = id;
                        orderDetail.sourceName = name;
                        orderDetail.sourceType = type;
                        orderDetail.sourceCode = code;

                        let priceTableDetail: SourceDetailResult = _find(details, (item: SourceDetailResult) => {
                            return item.productId === productId;
                        })!;

                        orderDetail.price = priceTableDetail.price;
                        let rowNode = this.productAgGrid.getRowNode(orderDetail.id);

                        if (rowNode) {
                            rowNode.gridApi.refreshCells();
                        }
                    }
                });

                this.$vs.notify({
                    title: '',
                    text: `Áp dụng ${data.type === 0 ? 'báo giá' : data.type === 1 ? 'hợp đồng' : 'bảng giá'} thành công`,
                    color: 'success'
                });
            }
        }
    }

    async onProductSelect(value: ProductSearchResult, type: string = 'product_bottom', isReturn: boolean = false) {
        if (!value) {
            return;
        }

        this.openLoading(true);

        try {
            let date = moment().format('DD-MM-YYYY');

            let inventory = _find(this.inventories, (item: any) => {
                return item.id === this.order.storeId;
            });

            let result = await this.orderService.getInventory(date, inventory ? inventory.erpCode : '', value.erpCode);
            this.openLoading(false);

            if (result && result.length > 0) {
                value.inventoryQuantity = parseFloat(result[0].soLuong);
            }
        } catch (error) {
            this.openLoading(false);
        }

        let details = new Array<OrderDetail>();
        let isSplitLine = this.order.isSplitLine;

        if (isReturn) {
            details = this.order.orderDetailsReturn;
        } else {
            details = this.order.orderDetails;
        }

        let no = details.length + 1;

        let productExist = _find(details, (orderDetail: OrderDetail) => {
            return orderDetail.product.id === value.id;
        });

        if (productExist && !isSplitLine) {
            let rowNode: any = null;

            if (isReturn) {
                rowNode = this.productAgGridReturn.getRowNode(productExist.id);
            } else {
                rowNode = this.productAgGrid.getRowNode(productExist.id);
            }

            if ((value.IsSelected && type === 'product_top') || type === 'product_bottom') {
                productExist.quantity = productExist.quantity + 1;
            } else {
                productExist.quantity = productExist.quantity - 1;

                if (productExist.quantity === 0) {
                    details = _filter(details, (item: OrderDetail) => {
                        return item.product.id !== value.id;
                    });
                }
            }

            productExist.totalPrice = productExist.price * productExist.quantity;

            if (rowNode) {
                rowNode.setDataValue("quantity", productExist.quantity);
                rowNode.data.quantity = productExist.quantity;
            }
        } else {
            let totalPrice = value.price;
            let sources = value.sources ? value.sources : new Array<SourceResult>()

            let orderDetail: OrderDetail = new OrderDetail(value.isChangePrice, "", value.code, value.id, value.name, value.image,
                value.groupId, value.groupName, value.groupIdPath, value.groupNamePath,
                1, value.price, 0, 0, totalPrice, 0, no, "", sources,
                value.unitId, value.unitName, value.inventoryQuantity,
                value.erpCode, value.sourceCode, value.sourceName, value.sourceId, value.sourceType,
                value.quota, value.vat, value.vatMoney);

            details.unshift(orderDetail);
        }

        // Tìm kiếm bảng giá theo khách hàng và sản phẩm.
        let productIds: number[] = [value.id];
        this.getPriceTableByCustomer(this.order.orderCustomer.customerId, productIds);
        this.orderDetailsSelected = new Array<OrderDetail>();
    }

    keyPressed(e: KeyboardEvent) {
        let keyCode = e.code;
        let allowCodes = ["F3", "F4", "F6", "F7"];

        if (allowCodes.indexOf(keyCode) > -1) {
            e.stopPropagation();
            e.preventDefault();
            e.stopImmediatePropagation();
        }

        if (this.formType === FormType.Return) {
            if (keyCode === "F9") {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();
            }
        }

        switch (keyCode) {
            case "F3":
                this.productSuggest.focus();

                break;
            case "F4":
                this.customerAddressTextHidden(false);
                this.customerSuggestion.suggestion.searchInput.focus();

                break;
            case "F6":
                if (this.orderDiscount && this.orderDiscount.$el) {
                    let inputElement = this.orderDiscount.$el.querySelector('input');
                    inputElement.focus();

                    setTimeout(() => {
                        inputElement.select();
                    }, 50);
                }

                break;
            case "F7":
                this.openOrderPayment();

                break;

            case "F9":
                this.exchangeReturn();

                break;
        }
    }

    openSettingColumnModal() {
        this.productAgGrid.openSettingColumnModal();
    }

    async onProductBottomSelected(product: ProductSearchResult) {
        await this.onProductSelect(product);
    }

    async onProductSelected(product: ProductSearchResult) {
        await this.onProductSelect(product, 'product_top', this.formType === FormType.Return);
    }

    onProductExchangeSelected(product: ProductSearchResult) {
        this.onProductSelect(product, 'product_top');
    }

    onInstallationStaffSelected(users: SuggestionModel[]) {
        this.order.installations = users.map((userSuggestion: SuggestionModel) => {
            const user = userSuggestion.data;
            return {
                id: user.id,
                fullName: user.fullName,
                avatar: user.avatar
            }
        });
    }

    onInstallationStaffRemoved(userSuggestion: SuggestionModel) {
        const index = this.order.installations.findIndex((item: any) => {
            return item.id === userSuggestion.id;
        });

        if (index != -1) {
            this.$delete(this.order.installations, index)
        }
    }

    onShippingStaffSelected(users: SuggestionModel[]) {
        this.order.shippings = users.map((userSuggestion: SuggestionModel) => {
            const user = userSuggestion.data;
            return {
                id: user.id,
                fullName: user.fullName,
                avatar: user.avatar
            }
        });
    }

    onShippingStaffRemoved(userSuggestion: SuggestionModel) {
        const index = this.order.shippings.findIndex((item: any) => {
            return item.id === userSuggestion.id;
        });

        if (index != -1) {
            this.$delete(this.order.shippings, index)
        }
    }

    boldenSuggestion(scope: any) {
        if (!scope)
            return scope;

        const {suggestion, query} = scope;
        let result = this.receiverSuggest.displayProperty(suggestion);

        if (!query)
            return result;

        const texts = query.split(/[\s-_/\\|.]/gm).filter((t: any) => !!t) || [''];

        return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    }

    async onStoreSearch(isBranch: boolean) {
        let result = await this.userConfigService.getUserConfig(this.currentUser.userId);

        if (result.code > 0) {
            let reusltData = result.data;

            if (reusltData) {
                if (isBranch) {
                    this.branches = reusltData.branches;
                } else {
                    this.inventories = reusltData.inventories;
                }
            }
        }
    }

    onOrderContractAccepted(deliveryData: any) {
        this.order.deliveryPlans = deliveryData;
        this.onSave(Status.TotalOrder);
    }

    createDeliveryPlan() {
        let isValid = this.validateRequest();

        if (!isValid)
            return;

        this.orderContractListComponent.show();
    }

    updateDeliveryPlan() {
        this.orderContractListComponent.show(true);
    }

    openOrderReturnList() {
        this.orderReturnList.openPopup();
    }

    onDiscountChanged(value: number, type: DiscountType) {
        this.order.discountType = type;
        this.order.discount = value;

        if (this.order.paidPrice) {
            let excessMoney = this.order.paidPrice - this.totalMoney;
            // this.order.excessMoney = excessMoney < 0 ? 0 : excessMoney;
            this.order.excessMoney = excessMoney;
        }
    }

    get isOrderChildOfOrderContract() {
        return !!this.order.fromOrderId && this.formType !== FormType.Return && this.order.type === OrderType.OrderContract;
    }

    get totalAmountReturn() {
        // let amount = this.order.amountReturn ? this.order.amountReturn : 0;
        let money = this.totalPriceReturn + this.order.returnCharge;
        this.order.totalAmountReturn = money;

        return money;
    }

    @Watch('order.returnCharge')
    onReturnChargeChange(newValue: number) {
        if (newValue > this.order.totalPriceReturn) {
            if (newValue < 0) {
                return;
            }

            setTimeout(() => {
                this.order.returnCharge = this.order.totalPriceReturn;
            }, 10);
        }
    }

    get totalMoneyExchangeReturn() {
        let money = this.totalAmountReturn - this.order.totalPrice;

        if (this.formType === FormType.Return) {
            this.order.paidPrice = Math.abs(money);
        }

        return money;
    }

    get isDisplayExchangeInformation() {
        return this.order.orderDetails && this.order.orderDetails.length > 0;
    }

    isFirstEdit: boolean = false;

    async getOrderById(orderId: string) {
        if (!orderId) {
            return;
        }

        try {
            let orderGetById: ActionResult<OrderGetByIdResult>;
            this.openLoading(true);
            let formType: FormType = FormType.Normal;

            if (this.formType === FormType.Return) {
                orderGetById = await this.orderService.orderReturnGetById(orderId);
                formType = FormType.Return;
            } else {
                orderGetById = await this.orderService.getById(orderId);
                this.isFirstEdit = true;
            }

            if (orderGetById.code > 0) {
                let orderData = orderGetById.data as OrderGetByIdResult;

                if (orderData) {
                    let name = "";

                    if (this.formType === FormType.Return) {
                        this.lastReturnTabIndex = this.lastReturnTabIndex + 1;
                        name = `${this.RETURN_TAB} ${this.lastReturnTabIndex}`;
                    } else {
                        this.lastTabIndex = this.lastTabIndex + 1;
                        name = `Update_${orderData.code}`;
                    }

                    let tab = new OrderTab(name);
                    tab.formType = formType;
                    this.tabs.push(tab);
                    tab.data.id = orderData.id;
                    tab.data.storeId = orderData.storeId;
                    tab.data.storeCode = orderData.storeCode;
                    tab.data.storeName = orderData.storeName;
                    tab.data.code = orderData.code;
                    tab.data.orderCustomer = orderData.customer;
                    tab.data.receiver = orderData.receiver;
                    tab.data.type = orderData.type;
                    tab.data.branchId = orderData.branchId;
                    tab.data.branchName = orderData.branchName;

                    if (this.formType === FormType.Normal) {
                        let productIds = new Array();
                        let formatType = "DD-MM-YYYY HH:mm";

                        // Gán thông tin người quản lý khách hàng.
                        let customerData = tab.data.orderCustomer;
                        this.managerOfCustomer = customerData.managerId ? [new SuggestionModel(customerData.managerId, customerData.managerFullName, '', '', true)] : [];

                        tab.data.shippingPrice = orderData.shippingPrice ? orderData.shippingPrice : 0;
                        tab.data.paidPrice = orderData.paidPrice;
                        tab.data.orderDate = orderData.orderDate;
                        tab.data.deliveryDate = orderData.deliveryDate;
                        tab.data.size = orderData.size;
                        tab.data.installationMethod = orderData.installationMethod;
                        tab.data.shippingMethod = orderData.shippingMethod;
                        tab.data.collectionMoney = orderData.collectionMoney ? orderData.collectionMoney : 0;
                        tab.data.discount = orderData.discount;
                        tab.data.discountType = orderData.discountType;
                        tab.data.vat = orderData.vat;
                        tab.data.totalPrice = orderData.totalPrice;
                        tab.data.totalProduct = orderData.totalProduct;
                        tab.data.weight = orderData.weight;
                        tab.data.note = orderData.note;
                        tab.data.noteInternal = orderData.noteInternal;
                        tab.data.status = orderData.status;
                        tab.data.fromOrderId = orderData.fromOrderId;
                        tab.data.installations = orderData.installations;
                        tab.data.shippings = orderData.shippings;
                        tab.data.paymentTerm = orderData.paymentTerm;
                        tab.data.attachFiles = orderData.attachFiles;

                        tab.data.orderPayments = orderData.payments;
                        tab.data.paymentTermDate = orderData.paymentTermDate;
                        this.orderDate = moment(orderData.orderDate).format(formatType);
                        this.paymentTermDate = moment(orderData.paymentTermDate).format(formatType);

                        let from = moment(orderData.deliveryDate.from).format(formatType);
                        let to = moment(orderData.deliveryDate.to).format(formatType);
                        this.$set(this.configDateTimePickerRange, 'defaultDate', [from, to]);

                        setTimeout(() => {
                            this.forceDeliveryDateRender += 1;
                        }, 10)

                        _each(orderData.orderDetails, (item: OrderDetail) => {
                            let orderDetail = new OrderDetail(item.isChangePrice, item.orderId, item.product.code, item.product.id, item.product.name, item.product.image,
                                item.product.groupId, item.product.groupName, item.product.groupIdPath, item.product.groupNamePath,
                                item.quantity, item.price, item.discount, item.discountType,
                                item.totalPrice, item.actualExport, 1, item.note, item.sources, item.unitId, item.unitName, item.inventoryQuantity, item.product.erpCode,
                                item.sourceCode, item.sourceName,
                                item.sourceId, item.sourceType, item.quota, item.vat, item.vatMoney);

                            orderDetail.id = item.id;
                            tab.data.orderDetails.push(orderDetail);
                            productIds.push(item.product.id);
                        });

                        if (productIds.length > 0) {
                            if (orderData.customer.customerId) {
                                await this.getPriceTableByCustomer(orderData.customer.customerId, productIds);
                            }
                        }

                        // Đơn con của đơn tổng.
                        if (orderData.fromOrderId) {
                            this.productAgGrid.orderChildColumnDefs();
                        }
                    } else if (this.formType === FormType.Return) {
                        tab.data.fromOrderId = orderData.id;
                        tab.data.vatReturn = orderData.vat;

                        _each(orderData.orderDetails, (item: OrderDetail) => {
                            let quantity = 0;

                            let orderDetail = new OrderDetail(item.isChangePrice, item.orderId, item.product.code, item.product.id, item.product.name, item.product.image,
                                item.product.groupId, item.product.groupName, item.product.groupIdPath, item.product.groupNamePath,
                                quantity, item.price, item.discount, item.discountType,
                                item.totalPrice, item.actualExport, 1, item.note, item.sources, item.unitId, item.unitName, item.inventoryQuantity, item.product.erpCode,
                                item.sourceCode, item.sourceName,
                                item.sourceId, item.sourceType, item.quota, item.vat, item.vatMoney, item.remainQuantity);

                            orderDetail.id = item.id;
                            tab.data.orderDetailsReturn.push(orderDetail);
                        });
                    }

                    if (orderData.customer) {
                        let customerData = orderData.customer;
                        let resultCustomerAddresses = await this.customerService.getAddressesByCustomer(customerData.customerId);

                        if (resultCustomerAddresses.code > 0) {
                            this.customerAddresses = resultCustomerAddresses.data;
                        }

                        if (customerData.customerAddress) {
                            let routeName = _map(customerData.customerAddress.routes, 'name');
                            tab.data.routeName = routeName.join(', ');
                            tab.data.orderCustomer.customerAddress.customAttributes = customerData.customerAddress.customAttributes;
                            this.customerAddressSelected = customerData.customerAddress;
                        }

                        let result = await this.orderService.getInformationGetByCustomer(customerData.customerId, orderData.customer.erpCode);

                        if (result && result.data) {
                            let resultData = result.data;

                            tab.data.orderCustomer.totalDebitBalance = resultData.totalDebitBalance;
                            // tab.data.orderCustomer.totalOwned = resultData.totalOwned;
                            // tab.data.orderCustomer.limit = resultData.limit;
                            tab.data.orderCustomer.status = resultData.status;
                        }
                    }
                    this.tabIndex = this.tabs.length - 1;
                    this.selectTab(tab, this.tabIndex);
                }
            }

            this.openLoading(false);
        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }

    async exchangeReturn() {
        if (this.order.orderDetailsReturn.length === 0) {
            this.$vs.notify({
                title: '',
                text: "Vui lòng chọn sản phẩm vào đơn hàng!",
                color: "danger"
            });

            return;
        }

        if (!this.order.storeId) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần chọn Kho xuất!",
                color: "danger"
            });

            return;
        }

        if (!this.order.orderCustomer.customerId) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần chọn khách hàng!",
                color: "danger"
            });

            return;
        }

        if (!this.order.receiver.fullName || !this.order.receiver.phoneNumber) {
            this.$vs.notify({
                title: '',
                text: "Bạn cần nhập thông tin người nhận!",
                color: "danger"
            });

            return;
        }

        this.openLoading(true);

        try {
            let orderReturn = new OrderReturn();
            orderReturn.vAT = this.order.vatReturn;
            orderReturn.orderDetails = this.order.orderDetailsReturn;
            orderReturn.totalPrice = this.order.totalPriceReturn;
            orderReturn.returnCharge = this.order.returnCharge;
            orderReturn.isImportInventory = this.order.isImportInventory;
            orderReturn.totalAmount = this.order.totalAmountReturn;

            let orderExchange = new OrderExchange();
            orderExchange.discount = this.order.discount;
            orderExchange.discountType = this.order.discountType;
            orderExchange.vAT = this.order.vat;
            orderExchange.orderDetails = this.order.orderDetails;
            orderExchange.totalPrice = this.order.totalPrice;
            orderExchange.isExportInventory = this.order.isExportInventory;

            let data = new OrderExchangeReturn();
            data.note = this.order.note;
            data.fromOrderId = this.order.fromOrderId;
            data.orderCustomer = this.order.orderCustomer;
            data.receiver = this.order.receiver;
            data.storeId = this.order.storeId;
            data.storeCode = this.order.storeCode;
            data.storeName = this.order.storeName;
            data.orderPayments = this.order.orderPayments;
            data.paidPrice = this.order.paidPrice;
            data.return = orderReturn;
            data.exchange = orderExchange;

            let result = await this.orderService.exchangeReturn(data);

            this.openLoading(false);
            if (result.code > 0) {
                this.$vs.notify({
                    title: '',
                    text: "Trả hàng thành công!",
                    color: "success"
                });

                // await this.$router.push({name: 'orderAdd'});
                this.orderFormRefresh(false, this.tabIndex);

                return;
            }
        } catch (error) {
            this.openLoading(false);

            this.$vs.notify({
                title: '',
                text: error.message,
                color: 'danger'
            });
        }
    }

    @Watch('order.isReturnAll')
    onIsReturnAllChange(newValue: boolean) {
        _each(this.order.orderDetailsReturn, (orderDetail: OrderDetail) => {
            let remainQuantity = orderDetail.remainQuantity ? orderDetail.remainQuantity : 0;
            orderDetail.quantity = newValue ? remainQuantity : 0;
        })
    }

    get isReturn() {
        let orderId = this.order.id;
        return !!(this.formType === FormType.Return && orderId);
    }

    get totalPriceReturn() {
        let amount = this.order.amountReturn ? this.order.amountReturn : 0;
        let vat = this.order.vatReturn ? this.order.vatReturn : 0;
        // let moneyIncludeDiscount = 0;

        let money = amount + vat;
        this.order.totalPriceReturn = money;

        return money;
    }

    onReturnFastSelected() {
        this.formType = FormType.Return;
        this.addTab();
    }

    onSaveOrdered() {
        this.save(Status.Ordered);
        this.isActiveInventoryPopup = false;
    }

    onClosePopup() {
        this.isActiveInventoryPopup = false;
    }

    onAddFileSuccess(fileIds: Array<string>) {
        this.order.fileIds = fileIds;
    }

    onManagerOfCustomerSelected(users: SuggestionModel[]) {
        let data = users[0];

        this.order.orderCustomer.managerId = data.id;
        this.order.orderCustomer.managerFullName = data.text;
    }

    onManagerOfCustomerRemoved(userSuggestion: SuggestionModel) {
        // this.order.orderCustomer.managerId = '';
        // this.order.orderCustomer.managerFullName = '';
    }

    private async getPriceTableByCustomer(customerId: string, productIds: Array<number>) {
        if (productIds.length === 0 || !customerId) {
            return;
        }

        let result = await this.customerService.getPriceTableByCustomer(customerId, productIds);

        if (result.code > 0) {
            let data = result.data;

            if (data) {
                let priceTables = data.priceTables;
                let contractQuotation = data.contractQuotation;

                _each(priceTables, (item: any) => {
                    let priceTableDetail = item.details;

                    if (priceTableDetail.length > 0) {
                        // So sánh sản phẩm trong danh sách bảng giá với sản phẩm đã chọn trong đơn hàng.
                        let productIdOfPriceTableDetail = _map(priceTableDetail, 'productId');
                        let id = item.priceTableId;
                        let name = item.priceTableName;
                        let type = item.type;
                        this.assignDataToSources(productIdOfPriceTableDetail, id, name, priceTableDetail, type);
                    }
                });

                _each(contractQuotation, (item: any) => {
                    let details = item.details;

                    if (details.length > 0) {
                        // So sánh sản phẩm trong danh sách bảng giá với sản phẩm đã chọn trong đơn hàng.
                        let productIdsDetails = _map(details, 'productId');
                        let id = item.id;
                        let name = item.name;
                        let type = item.type;
                        let code = item.code;

                        this.assignDataToSources(productIdsDetails, id, name, details, type, code);
                    }
                });
            }
        }
    }

    private async getOrderInfoOfCustomer(customerData: CustomerDetailResult) {
        if (!customerData) {
            return;
        }

        let customerId = customerData.id;
        let customerName = customerData.name;
        let phoneNumber = customerData.phoneNumber;
        let customerAddresses = customerData.addresses;
        this.customerAddresses = customerAddresses;
        this.order.orderCustomer.customerId = customerId;
        this.order.orderCustomer.name = customerName;
        this.order.orderCustomer.phoneNumber = phoneNumber;
        this.order.orderCustomer.taxCode = customerData.taxCode;
        this.order.orderCustomer.type = customerData.type;
        this.order.orderCustomer.idNumber = customerData.idNumber;
        this.order.orderCustomer.customerCode = customerData.code;
        this.order.orderCustomer.erpCode = customerData.erpCode;
        this.order.orderCustomer.managerId = customerData.managerId;
        this.order.orderCustomer.managerFullName = customerData.managerFullName;
        this.order.orderCustomer.salesChannelId = customerData.salesChannelId;
        this.order.orderCustomer.salesChannelName = customerData.salesChannelName;

        // Gán thông tin người quản lý khách hàng.
        this.managerOfCustomer = customerData.managerId ? [new SuggestionModel(customerData.managerId, customerData.managerFullName, '', '', true)] : [];

        let customerAddressPrimary = this.getDefaultCustomerAddress(customerAddresses);

        if (customerAddressPrimary) {
            this.order.orderCustomer.address = customerAddressPrimary.address;
        }

        let result = await this.orderService.getInformationGetByCustomer(customerId, this.order.orderCustomer.erpCode);
        let customerAddress: CustomerAddress = new CustomerAddress();

        if (result && result.data) {
            let resultData = result.data;
            this.order.orderCustomer.totalDebitBalance = resultData.totalDebitBalance < 0 ? 0 : resultData.totalDebitBalance;
            // this.order.orderCustomer.totalOwned = resultData.totalOwned;
            // this.order.orderCustomer.limit = resultData.limit;
            this.order.orderCustomer.status = resultData.status;

            if (resultData.receiver) {
                this.order.receiver.id = resultData.receiver.id;
                this.order.receiver.fullName = resultData.receiver.fullname;
                this.order.receiver.phoneNumber = resultData.receiver.phoneNumber;
            }

            if (resultData.customerAddress) {
                customerAddress = resultData.customerAddress;
            }
        }

        if (!this.order.receiver.fullName && !this.order.receiver.phoneNumber) {
            this.order.receiver.fullName = customerName;
            this.order.receiver.phoneNumber = phoneNumber;
        }

        if (customerAddress && customerAddress.id) {
            this.customerAddressSelected = customerAddress;
        } else {
            customerAddress = this.getDefaultCustomerAddress(customerAddresses);
            this.customerAddressSelected = customerAddress;
        }

        if (customerAddress) {
            let routeName = _map(customerAddress.routes, 'name');
            this.order.routeName = routeName.join(', ');
        }

        if (this.customerAddressSelected && this.customerAddressSelected.id) {
            let customAttributes = customerAddress ? customerAddress.customAttributes : [];

            // TODO: chưa hiển thị đẩy đủ địa chỉ.
            this.order.orderCustomer.address = customerAddress ? customerAddress.address : "";
            this.order.orderCustomer.customerAddress.customAttributes = customAttributes;
            await this.getDefaultAttribute(customAttributes);
        } else {
            this.order.orderCustomer.address = "";
        }

        if (this.order.orderDetails.length > 0) {
            let productIds = _map(this.order.orderDetails, 'product.id');
            await this.getPriceTableByCustomer(customerId, productIds);
        }
    }

    private assignDataToSources(productIdsDetails: any, id: any, name: any, details: any, type: any, code?: any) {
        _each(this.order.orderDetails, (orderDetail: OrderDetail) => {
            if (productIdsDetails.indexOf(orderDetail.product.id) > -1) {
                let item = new SourceResult();
                item.id = id;
                item.name = name;
                item.type = type;
                item.code = code;
                item.details = details;

                let priceTableExists = _find(orderDetail.sources, (priceTableItem: SourceResult) => {
                    return priceTableItem.id === item.id;
                });

                if (priceTableExists) {
                    priceTableExists.name = item.name;
                    priceTableExists.details = item.details;
                } else {
                    if (orderDetail.sources) {
                        // orderDetail.sources.splice(1, 0, item);
                        orderDetail.sources.push(item);
                    } else {
                        orderDetail.sources = [item];
                    }
                }

                if (type === 2 && orderDetail.sourceId !== undefined && orderDetail.sourceId === 0) {
                    orderDetail.sourceId = id;
                    orderDetail.sourceName = name;
                    orderDetail.sourceType = type;
                    // let detail = _head(item.details)!;

                    // TODO: trường hợp tách dòng phải check lại.
                    let detail = _find(item.details, (item: SourceDetailResult) => {
                        return item.productId === orderDetail.product.id;
                    }) as SourceDetailResult;

                    if (detail) {
                        let price = detail.price;
                        let vat = orderDetail.vat;
                        let discount = orderDetail.discount;
                        let discountType = orderDetail.discountType;
                        orderDetail.price = price;
                        orderDetail.vatMoney = calculateOrderDetailVatMoney(discount, discountType, price, orderDetail.quantity, vat);
                    }

                    let rowNode = this.productAgGrid.getRowNode(orderDetail.id);

                    if (rowNode) {
                        rowNode.gridApi.refreshCells();
                    }
                }
            }
        });
    }

    private customerAddressTextHidden(isShow: boolean) {
        if (!this.customerSuggestion || !this.customerSuggestion.$el) {
            return;
        }

        let element = this.customerSuggestion.$el.querySelector('.list-selected-items');

        if (element) {
            element.style.display = isShow ? '' : 'none';
            let elementSibling = element.nextElementSibling;

            if (elementSibling) {
                elementSibling.style.display = isShow ? 'none' : '';
            }
        }
    }

    private orderFormRefresh(isKeepData: boolean = false, tabIndex: number) {
        let customerPrevious: OrderCustomer = new OrderCustomer();
        let receiverPrevious: Receiver = new Receiver("", "");

        if (isKeepData) {
            let lastOrderTab = _find(this.tabs, (tab: OrderTab, index: number) => {
                return index === this.tabIndex;
            }) as OrderTab;

            if (lastOrderTab) {
                customerPrevious = lastOrderTab.data.orderCustomer;
                receiverPrevious = lastOrderTab.data.receiver;
            }
        }

        this.tabs = _filter(this.tabs, (value, index) => {
            return index !== tabIndex;
        });

        if (this.tabs.length === 0) {
            this.formType = FormType.Normal;
            this.addTab();
        } else {
            this.tabIndex = this.tabs.length - 1;
        }

        let currentOrderTab = _find(this.tabs, (tab: OrderTab, index: number) => {
            return index === this.tabs.length - 1;
        }) as OrderTab;

        if (isKeepData) {
            currentOrderTab.data.receiver = receiverPrevious;
            currentOrderTab.data.orderCustomer = customerPrevious;
        }

        if (this.inventories && this.inventories.length > 0) {
            let inventory: any = _head(this.inventories);
            currentOrderTab.data.storeId = inventory.id;
            currentOrderTab.data.storeCode = inventory.erpCode;
            currentOrderTab.data.storeName = inventory.name;
        }

        if (this.branches && this.branches.length > 0) {
            let branch: any = _head(this.branches);

            if (branch) {
                currentOrderTab.data.branchId = branch.id;
                currentOrderTab.data.branchName = branch.name;
            }
        }

        this.managerOfCustomer = [];
        this.selectTab(currentOrderTab, this.tabIndex);
        this.customerAddressTextHidden(false);
    }

    private async getDefaultAttribute(customAttributes?: CustomAttributeResult[]) {
        const result = await this.customerAddressService.getDefaultAttribute();
        if (result.code > 0) {
            this.order.orderCustomer.customerAddress.customAttributes = result.items.map((attributeResult: AttributeResult) => {
                const currentAttribute = customAttributes && customAttributes.length > 0
                    ? customAttributes.find((customAttribute: CustomAttributeResult) => {
                        return customAttribute.attributeId === attributeResult.id;
                    }) : null;
                return {
                    attributeId: attributeResult.id,
                    attributeName: attributeResult.name,
                    attributeDescription: attributeResult.description,
                    type: attributeResult.type,
                    attributeValueId: currentAttribute ? currentAttribute.attributeValueId : '',
                    attributeValueName: currentAttribute ? currentAttribute.attributeValueName : '',
                    isMultiple: attributeResult.isMultiple,
                    attributeValues: attributeResult.values
                } as CustomAttributeResult;
            });
        }
    }

    private getDefaultCustomerAddress(customerAddresses: Array<CustomerAddress>) {
        let address = _find(customerAddresses, (address: CustomerAddress) => {
            return address.isPrimary;
        }) as CustomerAddress;

        if (!address) {
            address = _head(customerAddresses) as CustomerAddress;
        }

        return address;
    }

    // private validateInventoryQuantity(orderDetails: Array<OrderDetail>){
    //     let productNameInValid = new Array<string>();
    //
    //     _each(orderDetails, (orderDetail: OrderDetail) => {
    //         if (orderDetail.quantity > orderDetail.inventoryQuantity) {
    //             productNameInValid.push(orderDetail.product.name);
    //         }
    //     });
    //
    //     return productNameInValid;
    // }

    private openLoading(value: boolean) {
        if (value) {
            this.$vs.loading();
        } else {
            this.$vs.loading.close();
        }
    }
}
