













import {Component, Vue} from 'vue-property-decorator';
import {formatNumber} from "@/common";

@Component({})
export default class QuantityCellRenderer extends Vue {
    params: any = null;
    
    get quantity(){
        let quantity = this.params.value;
        let remainQuantity = this.params.data.remainQuantity;
        
        if (remainQuantity) {
            if (quantity > remainQuantity) {
                quantity = remainQuantity;
                this.params.data.quantity = quantity;
            }
        }

        return formatNumber(quantity)
    }

    get remainQuantity(){
        return formatNumber(this.params.data.remainQuantity);
    }

    get isInValidQuantity(){
        let data = this.params.data;

        if (data.quantity > data.inventoryQuantity) {
            return true;
        }

        return false;
    }

    get errorMessage(){
        return "Số lượng lớn hơn tồn kho";
    }
}
