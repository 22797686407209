



































import {Component, Ref, Vue, Watch, Prop} from 'vue-property-decorator';
import {AgGridVue} from 'ag-grid-vue';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';

import {Order} from '@/models/order/Order';
import {OrderDetail} from '@/models/order/OrderDetail';

import _filter from "lodash/filter";
import _find from "lodash/find";
import _each from "lodash/each";

import CellRendererActions from '@/views/order/order-edit/cell-renderer/CellRendererActions.vue';
import CellRendererName from '@/views/order/order-edit/cell-renderer/CellRendererName.vue';
import QuantityCellRenderer from '@/views/order/order-edit/cell-renderer/QuantityCellRenderer.vue';
import CustomPinnedRowRenderer from "@/views/order/order-list/CustomPinnedRowRenderer.vue";
import PriceTableDropDownCellEditor from "@/views/order/order-edit/cell-editor/PriceTableDropDownCellEditor.vue";
import NumbericCellEditor from "@/views/order/order-edit/cell-editor/NumbericCellEditor.vue";
import DiscountCellEditor from "@/views/order/order-edit/cell-editor/DiscountCellEditor.vue";
import {SettingColumnResult, SettingColumnSelect} from "@/models/settingColumn/SettingColumnResult";
import {SettingColumnService} from '@/services/SettingColumnService';
import {ActionResult} from '@/models/ActionResult';

import { formatNumber, calculateOrderDetailVatMoney } from "@/common";
import { DiscountType } from "@/constants/Order";

@Component({
    components: {
        AgGridVue,
        SettingColumns: () => import(/* webpackChunkName: "SettingColumns" */ '@/components/SettingColumns.vue'),
    }
})
export default class ProductAgGrid extends Vue {
    @Ref('settingColumnsRef') settingColumnsRef!: any;
    @Prop({required: true}) data!: Order;
    @Prop({default: false}) isChildOfOrderContract !: boolean;
    @Prop({default: false}) isReturn !: boolean;

    private settingColumnService: SettingColumnService;

    constructor(){
        super();

        this.settingColumnService = new SettingColumnService();
    }

    gridOptions: any = null;
    gridColumnApi: any = null;
    gridApi: any = null;
    pinnedTopRowData: any = null;
    getRowNodeId: any = null;
    getRowHeight: any = null;
    onRowSelected: any = null;
    productSelectedColumnDefs: any = null;
    orderDetailsSelected: Array<OrderDetail> = new Array<OrderDetail>();

    settingColumnConfig: SettingColumnResult = new SettingColumnResult();
    defaultSettingColumns: any = [];
    settingColumnModule: string = "OrderAddProductList";
    rowData: Array<OrderDetail> = new Array<OrderDetail>();

    defaultColDef: any = {
        editable: false,
        resizable: true,
    };

    cellRenderComponents: any = {
        CellRendererActions,
        CellRendererName,
        QuantityCellRenderer
    };

    frameworkComponents: any = {
        CustomPinnedRowRenderer
    };

    @Watch('data')
    onDataChange(newValue: Array<OrderDetail>){
        this.rowData = newValue;
    }

    @Watch('rowData', {deep: true, immediate: false})
    onOrderDetailChanged(value: OrderDetail[]) {
        if (value) {
            this.pinnedTopRowData = this.createPinnedRowData(value);
        }
    }

    async beforeMount() {
        this.gridOptions = {
            rowSelection: 'multiple',
            suppressCellSelection: false,
            localeText: {
                noRowsToShow: 'Không có dữ liệu',
            }
        };

        this.getRowNodeId = (data: OrderDetail) => {
            return data.id;
        };

        this.getRowHeight = (params: any) => {
            return 47;
        };

        this.onRowSelected = (params: any) => {
            let data = params.data as OrderDetail;

            if (params.node.selected) {
                this.orderDetailsSelected.push(data);
            } else {
                let id = data.id;

                this.orderDetailsSelected = _filter(this.orderDetailsSelected, (item: OrderDetail) => {
                    return item.id !== id;
                });
            }

            this.$emit('productSelected', this.orderDetailsSelected);
        };

        this.getDefaultColumnSetting();
        this.settingColumnService.getUserConfig().then((result: ActionResult<any>) => {
            this.productColumnDefs(result == null || result.data == null || !result.data.isUpdatePrice ? false: true);
        });

        this.settingColumnService.getByModuleName(this.settingColumnModule).then((result: ActionResult<SettingColumnResult>) => {
            this.settingColumnConfig = result.data as SettingColumnResult;
            if (!this.settingColumnConfig) {
                this.settingColumnConfig = new SettingColumnResult(this.settingColumnModule);
                this.settingColumnConfig.columnConfigData = this.defaultSettingColumns;
            }

            if (this.settingColumnsRef) {
                this.settingColumnsRef.agGridRefresh(this.settingColumnConfig);
            }
        });
    }

    async mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
        window.addEventListener('resize', this.onWindowResize);
    }

    beforeDestroy() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    get isOrderChildOfOrderContract() {
        return this.isChildOfOrderContract;
    }

    getRowStyle(params: any) {
        if (params.node.rowPinned) {
            return {"font-weight": "bold"};
        }
    }

    onWindowResize() {
        this.sizeColumnsToFit(100);
    }

    onGridReady(params: any) {
        this.sizeColumnsToFit(500);
    }

    deleteProduct(product: OrderDetail) {
        this.rowData = _filter(this.rowData, (value: OrderDetail) => {
            return value.id !== product.id
        });

        this.$emit('deleteProduct',  product.id);
    }

    settingColumnUpdated(config: SettingColumnResult) {
        this.settingColumnConfig = config;
        this.gridApi.sizeColumnsToFit();
    }

    openSettingColumnModal() {
        this.settingColumnsRef.openSettingColumnModal();
    }

    getRowNode(id: string){
        return this.gridApi.getRowNode(id);
    }

    orderChildColumnDefs(){
        let fieldsRemove = ['actions'];

        this.productSelectedColumnDefs = _filter(this.productSelectedColumnDefs, (item: any) => {
            return fieldsRemove.indexOf(item.field) === -1;
        });

        let noColumn = _find(this.productSelectedColumnDefs, (item: any) => {
            return item.field === 'no'
        });

        if (noColumn) {
            noColumn.width = 50;
            delete noColumn.checkboxSelection;
            delete noColumn.headerCheckboxSelectionFilteredOnly;
            delete noColumn.headerCheckboxSelection;
        }

        this.gridApi.setColumnDefs(this.productSelectedColumnDefs);
    }

    private createPinnedRowData(orderDetails: OrderDetail[]) {

        let result: any = [];
        let sumTotalPrice = 0;
        let sumWeight = 0;
        let sumVat = 0;
        let rowCount = orderDetails.length;

        _each(orderDetails, (orderDetail: OrderDetail) => {
            let discount = orderDetail.discount;
            let discountType = orderDetail.discountType;
            let price = orderDetail.price;
            let vat = orderDetail.vat;
            let money = 0;

            orderDetail.quantity = parseFloat(orderDetail.quantity.toString());

            if (discountType === DiscountType.Percent) {
                money = orderDetail.quantity * price * (1 - discount / 100);
            } else if (discountType === DiscountType.Money) {
                money = orderDetail.quantity * (price - discount);
            }

            orderDetail.totalPrice = Math.round(money);
            sumTotalPrice = sumTotalPrice + orderDetail.totalPrice;
            sumWeight = sumWeight + ((orderDetail.quota ? orderDetail.quota : 0) * orderDetail.quantity);
            let vatMoney = calculateOrderDetailVatMoney(discount, discountType, price, orderDetail.quantity, vat);
            // debugger;
            //
            // if (orderDetail && orderDetail.vatMoney === 0) {
            //     orderDetail.vatMoney = vatMoney;
            // }
            //
            // if (!orderDetail.quantity) {
            //     orderDetail.vatMoney = 0;
            // }

            orderDetail.vatMoney = vatMoney;

            //  * orderDetail.quantity
            sumVat += (orderDetail.vatMoney ? orderDetail.vatMoney : 0);
            orderDetail.no = rowCount--;
        });

        if (this.gridApi) {
            this.gridApi.refreshCells();
        }

        sumVat = Math.round(sumVat);
        sumTotalPrice = Math.round(sumTotalPrice);

        this.$emit('productListChanged', {orderDetails, sumTotalPrice, sumWeight, sumVat})

        result.push({
            totalPrice: sumTotalPrice,
        });

        return result;
    }

    private getDefaultColumnSetting() {
        this.defaultSettingColumns = [
            new SettingColumnSelect('No', 'no', true),
            new SettingColumnSelect('ProductName', 'product.name', true),
            new SettingColumnSelect('SourceId', 'sourceId', true),
            new SettingColumnSelect('Quantity', 'quantity', true),
            new SettingColumnSelect('Price', 'price', true),
            new SettingColumnSelect('InventoryQuantity', 'inventoryQuantity', true),
            new SettingColumnSelect('Discount', 'discount', true),
            new SettingColumnSelect('VAT', 'vat', true),
            new SettingColumnSelect('VatMoney', 'vatMoney', true),
            new SettingColumnSelect('TotalPrice', 'totalPrice', true),
            new SettingColumnSelect('Actions', 'actions', true),
        ]
    }

    private productColumnDefs(isUpdatePrice: boolean){

        this.productSelectedColumnDefs = [
            {
                headerName: 'STT',
                field: 'no',
                width: 100,
                checkboxSelection: () => {
                    return !this.isReturn;
                },
                headerCheckboxSelectionFilteredOnly: () => {
                    return !this.isReturn;
                },
                headerCheckboxSelection: () => {
                    return !this.isReturn;
                },
            },
            {
                headerName: 'Tên sản phẩm',
                field: 'product.name',
                width: 320,
                cellRendererFramework: CellRendererName,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellClass: "product-name",
                cellRendererParams: () => {
                    return {
                        isOrderChildOfOrderContract: this.isOrderChildOfOrderContract,
                        isReturn: this.isReturn
                    }
                }
            },
            {
                headerName: "Bảng giá",
                field: "sourceId",
                width: 150,
                cellEditorFramework: PriceTableDropDownCellEditor,
                cellEditorParams: function (params: any) {
                    let sources = params.data.sources;

                    return {
                        options: sources
                    }
                },
                editable: (params: any) => {
                    if (this.isReturn) {
                        return false;
                    }

                    return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                },
                cellRenderer: (params: any) => {
                    let sources = params.data.sources, value = params.value;

                    if (typeof value === "string") {
                        return value;
                    } else {
                        let priceTable = _find(sources, (item) => {
                            return item.id === params.value;
                        });

                        return priceTable ? priceTable.name : '';
                    }
                },
            },
            {
                headerName: 'Số lượng',
                field: 'quantity',
                width: 80,
                cellEditorFramework: NumbericCellEditor,

                cellRendererFramework: QuantityCellRenderer,
                editable: (params: any) => {
                    return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                onCellValueChanged: (params: any) => {
                    if (params.newValue !== params.oldValue) {
                        let orderDetail = _find(this.rowData, (item: any) => {
                            return item.id === params.data.id;
                        });

                        if (orderDetail) {
                            orderDetail.vatMoney = 0;
                        }
                    }
                }
            },
            {
                headerName: 'Đơn giá',
                field: 'price',
                width: 90,
                cellStyle: {textAlign: "right"},
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellEditorFramework: NumbericCellEditor,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                editable: (params: any) => {
                    if (this.isReturn) {
                        return false;
                    }

                    return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                },
                onCellValueChanged: (params: any) => {
                    if (params.newValue !== params.oldValue) {
                        // Khi thay đổi bảng giá và không được phép sửa giá của sản phẩm.
                        if(!params.data.isChangeSource && !params.data.isChangePrice)
                            params.data.price = params.oldValue;

                        let orderDetail = _find(this.rowData, (item: any) => {
                            return item.id === params.data.id;
                        });

                        if (orderDetail) {
                            orderDetail.vatMoney = 0;
                        }

                        if (params.data.isChangeSource)
                            params.data.isChangeSource = false;
                    }
                }
            },
            {
                headerName: 'Tồn kho',
                field: 'inventoryQuantity',
                width: 80,
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                cellStyle: {textAlign: "right"},
                pinnedRowCellRenderer: "CustomPinnedRowRenderer"
            },
            {
                headerName: 'Chiết khấu',
                field: 'discount',
                width: 85,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellStyle: {textAlign: "right"},
                cellEditorFramework: DiscountCellEditor,
                cellEditorParams: {
                    isOnlyPercent: false
                },
                cellRenderer: (data: any) => {
                    return formatNumber(data.value)
                },
                editable: (params: any) => {
                    if (this.isReturn) {
                        return false;
                    }

                    return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                },
                onCellValueChanged: (params: any) => {
                    if (params.newValue !== params.oldValue) {
                        let orderDetail = _find(this.rowData, (item: any) => {
                            return item.id === params.data.id;
                        });

                        if (orderDetail) {
                            orderDetail.vatMoney = 0;
                        }
                    }
                }
            },
            {
                headerName: '% VAT',
                field: 'vat',
                width: 80,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellStyle: {textAlign: "right"},
                cellEditorFramework: NumbericCellEditor,
                editable: (params: any) => {
                    if (this.isReturn) {
                        return false;
                    }

                    return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                },
                valueFormatter: (params: any) => {
                    return formatNumber(params.value);
                }
            },
            {
                headerName: 'Tiền VAT',
                field: 'vatMoney',
                width: 80,
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
                cellStyle: {textAlign: "right"},
                // cellEditorFramework: NumbericCellEditor,
                // editable: (params: any) => {
                //     if (this.isReturn) {
                //         return false;
                //     }
                //
                //     return !params.node.isRowPinned() && !this.isOrderChildOfOrderContract;
                // },
                valueFormatter: (params: any) => {
                    return formatNumber(Math.round(params.value));
                }
            },
            {
                headerName: 'Thành tiền',
                field: 'totalPrice',
                width: 110,
                cellStyle: {textAlign: "right"},
                valueFormatter: (params: any) => {
                    return formatNumber(Math.round(params.value));
                },
                valueGetter: function (params: any) {
                    if (params.node.isRowPinned()) {
                        return Math.round(params.data.totalPrice);
                    } else {
                        let price = params.getValue("price");
                        let quantity = params.getValue("quantity");
                        let discount = params.getValue("discount");
                        let discountType = params.data.discountType;
                        let money = 0;

                        if (discountType === DiscountType.Percent) {
                            money = price * (1 - discount / 100);
                        } else if (discountType === DiscountType.Money) {
                            money = price - discount;
                        }

                        return Math.round(money * quantity);
                    }
                }
            },
            {
                headerName: '',
                field: 'actions',
                width: 45,
                cellRendererFramework: CellRendererActions,
                cellRendererParams: {
                    onClick: this.deleteProduct
                },
                pinnedRowCellRenderer: "CustomPinnedRowRenderer",
            }
        ];
    }

    @Watch('isReturn')
    onIsReturnChange(newValue: boolean){
        this.gridColumnApi.setColumnVisible('actions', !newValue);
        this.sizeColumnsToFit(10);
    }

    private sizeColumnsToFit(timeout: number){
         setTimeout(() => {
            this.gridApi.sizeColumnsToFit();
        }, timeout);
    }
}

