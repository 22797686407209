



























import {Component, Vue, Ref, Watch} from 'vue-property-decorator';
import {Money} from 'v-money';
import { DiscountType } from "@/constants/Order";
import { ICellEditorParams } from "ag-grid-community/dist/lib/interfaces/iCellEditor";
import Discount from "@/components/order/Discount.vue";

@Component({
    components:{
        Money,
        Discount
    }
})
export default class DiscountCellEditor extends Vue{
    @Ref('valueRef') valueRef !: any;
    @Ref('discount') discount !: any;

    discountType = DiscountType;
    params: any;
    value: any = null;
    // productDiscountType: DiscountType = DiscountType.Percent;

    moneyOption: any = {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 0,
        masked: false
    };

    created(){
        this.value = this.params.value;
        // this.productDiscountType = this.params.data.discountType;
    }

    isOnlyPercent(){
        return this.params.isOnlyPercent;
    }

    // onChangeDiscountType(type: DiscountType){
    //     this.productDiscountType = type;

    //     if (this.productDiscountType === DiscountType.Percent) {
    //         if ( this.value && this.value > 100) {
    //             this.value = 0;
    //         }
    //     }
    // }

    // @Watch('value', {deep: true, immediate: false})
    // onDiscountChanged(newValue: number, oldValue: number){
    //     // let price = this.params.data.price;

    //     if (newValue > 100 || newValue < -100) {
    //         this.productDiscountType = DiscountType.Money;
    //         this.params.data.discountType = DiscountType.Money;
    //     }
    //     else if (newValue <= 100 || newValue >= -100) {
    //         this.productDiscountType = DiscountType.Percent;
    //         this.params.data.discountType = DiscountType.Percent;
    //     }

    //     // if (this.productDiscountType === DiscountType.Money) {
    //     //     if (newValue > price) {
    //     //         this.value = 0;
    //     //     }
    //     // }
    //     // else{
    //     //     this.value = newValue;
    //     // }
    // }

    onDiscountChanged(value: number, type: DiscountType){
        this.value = value;
        this.params.data.discountType = type;
    }

    getValue(): any {
        return this.value;
    }

    afterGuiAttached() {
        // let inputEl = this.valueRef.$el;

        // inputEl.focus();
        // inputEl.select();
        this.discount.focus();
    }

    isPopup() {
        return true;
    }
}
