import axios from 'axios';
import { SearchResult } from '@/models/SearchResult';
import {ReceiverGetByCustomerResult} from '@/models/order/ReceiverGetByCustomerResult';

export class ReceiverService {
    private readonly url = `${process.env.VUE_APP_ORDER_URL}api/v1/receivers`;

    search(customerId: string, keyword: string, page: number = 1, pageSize: number = 10): Promise<SearchResult<ReceiverGetByCustomerResult>> {
        return axios.get(this.url, {
            params: {
                customerId,
                keyword,
                page,
                pageSize
            }
        });
    }
}