import axios from 'axios';
import {ProductSearchResult} from "@/models/product/ProductSearchResult";
import {SearchResult} from '@/models/SearchResult';
import {QueryLoadOption} from "@/models/QueryLoadOptions";

export class ProductService {
    private readonly url = `${process.env.VUE_APP_PRODUCT_URL}api/v1/products`;

    search(customerId: string, keyword: string, storeCode: string, page: number = 1, pageSize: number = 10): Promise<SearchResult<ProductSearchResult>> {
        return axios.get(this.url, {
            params: {
                customerId,
                keyword,
                storeCode,
                page,
                pageSize
            }
        });
    }

    searchByAttribute(loadOption: QueryLoadOption): Promise<SearchResult<ProductSearchResult>> {
        return axios.post(`${this.url}/searchy-by-attribute`, loadOption);
    }
}