








import {Component, Vue, Watch, Ref} from 'vue-property-decorator';
import { SourceDetailResult } from "@/models/order/SourceDetailResult";
import _find from 'lodash/find';

@Component({})
export default class PriceTableDropDownCellEditor extends Vue {
    @Ref('select') select !: any;
    params: any = null;
    options: any = null;
    value: any = null;

    created(){
        this.options = this.params.options;
        let value = this.params.value;

        if (typeof value === "string") {
            let paymentMethod = _find(this.options, (item: any) => {
                return item.name === this.params.value;
            });

            this.value = paymentMethod ? paymentMethod.id : 0;
        }
        else{
            this.value = value;
        }
    }

    @Watch('value')
    onValueChanged(priceTableValue: number, oldPriceTableValue: number){
        if (this.isNotNullAndUndefined(priceTableValue) && this.isNotNullAndUndefined(oldPriceTableValue) && priceTableValue !== oldPriceTableValue) {
            let option = _find(this.options, (option: any) => {
                return option.id === priceTableValue;
            });

            if (option) {
                let id = option.id;
                let name = option.name;
                let type = option.type;
                let code = option.code;
                let details = option.details;
                let productId = this.params.data.product.id;
                
                let detail: SourceDetailResult = _find(details, (item: SourceDetailResult) => {
                    return item.productId === productId;
                })!;

                if (detail) {
                    this.params.node.setDataValue('price', detail.price);
                    this.params.data.sourceId = id;
                    this.params.data.sourceName = name;
                    this.params.data.sourceType = type;
                    this.params.data.sourceCode = code;
                    // this.params.data.isChangePrice = true;
                    this.params.data.isChangeSource = true;
                }
            }
        }
    }

    getValue() {
        return this.value;
    }

    afterGuiAttached() {
        this.select.focus();
        // this.select.select();
    }

    private isNotNullAndUndefined(value: any){
        return value !== undefined && value !== null;
    }
}
