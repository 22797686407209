import { DiscountType } from "@/constants/Order";
import { SourceResult } from "@/models/order/SourceResult";
import { genarateId } from "@/common";

export class OrderDetail {
    id: string;
    orderId: string;
    product: Product;
    quantity: number;
    price: number;
    discount: number;
    discountType: DiscountType;
    totalPrice: number;
    actualExport: number;
    no: number;
    note: string;
    quota?: number;
    vat?: number;
    sources: Array<SourceResult>;
    unitId: number;
    unitName: string;
    vatMoney?: number;
    inventoryQuantity: number;

    sourceId?: number;
    sourceName: string;
    sourceCode: string;
    sourceType?: number;
    remainQuantity?: number;
    isChangePrice: boolean;
    isChangeSource: boolean;

    constructor(isChangePrice:boolean, orderId: string, code: string, productId: number, name: string, productImage: string,
        groupId: number, groupName: string, groupIdPath: string, groupNamePath: string,
        quantity: number, price: number, discount: number, discountType: DiscountType,
        totalPrice: number, actualExport: number, no: number, note: string, sources: Array<SourceResult>, 
        unitId: number, unitName: string, inventoryQuantity: number, erpCode: string,
        sourceCode: string, sourceName: string, sourceId?: number, sourceType?: number, 
        quota?: number, vat?: number, vatMoney?: number, remainQuantity?: number) {

        this.product = new Product();
        this.id = genarateId();
        this.orderId = orderId;

        this.product.id = productId
        this.product.code = code;
        this.product.name = name;
        this.product.image = productImage;
        this.product.groupId = groupId;
        this.product.groupName = groupName;
        this.product.groupIdPath = groupIdPath;
        this.product.groupNamePath = groupNamePath;
        this.product.erpCode = erpCode;

        this.quantity = quantity;
        this.price = price;
        this.discount = discount;
        this.discountType = discountType;
        this.totalPrice = totalPrice;
        this.sourceId = sourceId;
        this.sourceName = sourceName;
        this.sourceType = sourceType;
        this.sourceCode = sourceCode;
        this.sources = sources;

        this.actualExport = actualExport;
        this.no = no;
        this.note = note;
        this.quota = quota;
        this.vat = vat;
        this.unitId = unitId;
        this.unitName = unitName;
        this.vatMoney = vatMoney;
        this.inventoryQuantity = inventoryQuantity;
        this.remainQuantity = remainQuantity;
        this.isChangePrice = isChangePrice;
        this.isChangeSource = false;
    }
}

export class Product {
    id: number;
    code: string;
    name: string;
    image: string;
    groupId:number;
    groupName: string;
    groupIdPath: string;
    groupNamePath: string;
    erpCode: string;
}
